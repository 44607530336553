<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div class="banner-title">智慧校车</div>
        <div class="banner-subtitle">
          智慧校车管理平台融合技术、集成数据，实现车辆位置监控、危险驾驶行为分析、路线智能管理等功能。通过物联网技术强化校车管控能力，实现车辆定位、安全告警、指挥调度等功能；通过AI算法实时分析车辆运行数据，自动化识别车辆状况异常、危险驾驶、路线偏离等情况，并及时向平台管理员报警；平台管理员可快速获取报警事件的位置、时间、影像、周边情况等信息，辅助平台进行快速、准确为校车提供安全防范以及应急救援。
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="type-area">
        <div class="divider">
          <el-divider>系统亮点</el-divider>
        </div>
        <div class="advantage-main">
          <div class="item" v-for="item in advantageList">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="type-area">
      <div class="divider" style="padding-top: 120px">
        <el-divider>智慧校车驾驶舱</el-divider>
      </div>
    </div>

    <div class="process">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统智能监控各种安全风险状况，有效解决人力成本高、响应不及时等问题
          </div>
          <div class="dot-plot">智能报警</div>
        </div>
        <div class="process-main">
          <div>
            <div v-for="item in processList">
              <img :src="item.img" alt="" /><span>{{ item.title }}</span>
            </div>
          </div>
          <img src="@/assets/images/表单引擎插图备份.png" alt="" />
          <div style="margin-top: 380px">
            <div v-for="item in processList2">
              <img :src="item.img" alt="" /><span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            功能完整的任务推送和提醒机制，赋能高效的工作任务处理
          </div>
          <div class="dot-plot">车辆监控</div>
        </div>
        <div class="engine-main">
          <div>
            <div v-for="item in engineList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner9.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统智能监控各种安全风险状况，有效解决人力成本高、响应不及时等问题
          </div>
          <div class="dot-plot">远程视频</div>
        </div>
        <div class="process-main task-main">
          <img src="@/assets/images/banner10.png" alt="" />
          <div>
            <div v-for="item in taskList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine system">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            司机和驾驶舱管理员实现音视频通话，适用于紧急状况沟通、指挥调度等场景
          </div>
          <div class="dot-plot">实时音视频通话</div>
        </div>
        <div class="engine-main system-main">
          <div>
            <div v-for="item in systemList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner11.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process driving">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统按照设定时间范围显示车辆行进轨迹，会对行进路线进行智能分析
          </div>
          <div class="dot-plot">驾驶行为分析</div>
        </div>
        <div class="process-main driving-main">
          <img src="@/assets/images/banner20.png" alt="" />
          <div>
            <div v-for="item in drivingList">
              <span v-if="item.title">{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine guardian">
      <div class="type-area">
        <div class="divider">
          <el-divider>家长端</el-divider>
        </div>
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            校车地图展示校车实时位置、行驶轨迹、站点分布信息
          </div>
          <div class="dot-plot">校车动态</div>
        </div>
        <div class="engine-main guardian-main">
          <div>
            <div v-for="item in parentsList">
              <span v-if="item.title">{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner12.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process notice">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            学生上学、放学的乘车信息自动推送至学生家长
          </div>
          <div class="dot-plot">乘车通知</div>
        </div>
        <div class="process-main notice-main">
          <img src="@/assets/images/banner13.png" alt="" />
          <div>
            <div v-for="item in noticeList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine my">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            司机和驾驶舱管理员实现音视频通话，适用于紧急状况沟通、指挥调度等场景
          </div>
          <div class="dot-plot">我的</div>
        </div>
        <div class="engine-main my-main">
          <div>
            <div v-for="item in myList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner14.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process driver">
      <div class="type-area">
        <div class="divider">
          <el-divider>司机端</el-divider>
        </div>
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            车载终端显示校车行驶路线、途径站点以及各站点接送学生名单
          </div>
          <div class="dot-plot">学生接送</div>
        </div>
        <div class="process-main driver-main">
          <img src="@/assets/images/banner13.png" alt="" />
          <div>
            <div v-for="item in driverList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine message">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            司机发现异常情况时，可以通过车载终端发起请求，驾驶舱管理员报告情况
          </div>
          <div class="dot-plot">即时通信</div>
        </div>
        <div class="engine-main message-main">
          <div>
            <div v-for="item in messageList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner15.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process driver">
      <div class="type-area">
        <div class="divider">
          <el-divider>系统管理后台</el-divider>
        </div>
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            对校车档案化管理，确保能与运营单位、所服务学校、司职人员等信息进行关联
          </div>
          <div class="dot-plot">校车管理</div>
        </div>
        <div class="process-main manage-main">
          <img src="@/assets/images/banner16.png" alt="" />
          <div>
            <div v-for="item in manageList">
              <span v-if="item.title">{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine maintenance">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            校车站点维护功能是对校车所有途经站点进行信息维护，站点与线路进行关联
          </div>
          <div class="dot-plot">站点维护</div>
        </div>
        <div class="engine-main maintenance-main">
          <div>
            <div v-for="item in maintenanceList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner17.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process routeManagement">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统建立学生与线路、站点的关联，根据学生分布情况智能规划校车行驶路线
          </div>
          <div class="dot-plot">路线管理</div>
        </div>
        <div class="process-main routeManagement-main">
          <img src="@/assets/images/banner18.png" alt="" />
          <div>
            <div v-for="item in routeManagementList">
              <span v-if="item.title">{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine studentManagement">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            建立学生档案，通过认证的家长可以在家长端上传学生照片、编辑家庭住址信息
          </div>
          <div class="dot-plot">学生管理</div>
        </div>
        <div class="engine-main studentManagement-main">
          <div>
            <div v-for="item in studentManagementList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner19.png" alt="" />
        </div>
      </div>
    </div>

    <div class="contact_us">
      <div class="type-area">
        <span>请联系我们, 您将获得全程支持咨询服务</span>
        <el-button type="primary">联系我们</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

export default {
  name: "solution", // 解决方案
  data() {
    return {
      advantageList: [
        {
          title: "车辆信息实时监控",
          content: "支持Android、iOS、MacOS、Windows等平台间进行通畅音视频通话",
          img: require("@/assets/images/车辆监控实心.png"),
        },
        {
          title: "自动化智能告警",
          content: "完全私有化部署，音视频通信运行于专有网络中，适应多种场景",
          img: require("@/assets/images/自动告警.png"),
        },
        {
          title: "上下车AI刷脸打卡",
          content: "支持720、1080p高清画质，提供优质稳定的通化体验",
          img: require("@/assets/images/刷脸打卡.png"),
        },
        {
          title: "可视化指挥调度",
          content:
            "支持实时消息通信，支持文字、图片、文件等格式，可进行自定义消息开发",
          img: require("@/assets/images/可视化调度.png"),
        },
        {
          title: "支持多业务系统",
          content:
            "分布式架构模式, 以独立的微服务方式运行, 各业务系统可以直接调用",
          img: require("@/assets/images/音视频留存.png"),
        },
        {
          title: "车辆到站实时提醒",
          content: "开放账号、通话相关API接口，支持新业务场景的功能开发",
          img: require("@/assets/images/到站提醒.png"),
        },
      ],
      processList: [
        {
          title: "碰撞报警",
          img: require("@/assets/images/pengzhuangbaojing.png"),
        },
        {
          title: "超载报警",
          img: require("@/assets/images/chaozaibaojing.png"),
        },
        {
          title: "超速报警",
          img: require("@/assets/images/chapsubaojing.png"),
        },
        {
          title: "紧急报警",
          img: require("@/assets/images/jinjibaojing.png"),
        },
      ],
      processList2: [
        {
          title: "危险驾驶报警",
          img: require("@/assets/images/weixianjiashibaojing.png"),
        },
        {
          title: "路线偏离报警",
          img: require("@/assets/images/luxianpianlibaojing.png"),
        },

        {
          title: "学生遗落报警",
          img: require("@/assets/images/xueshengyiluobaojing.png"),
        },
      ],
      engineList: [
        {
          title: "车辆信息",
          subTitle:
            "地图界面点击车辆即可查看车辆详情信息，包括车牌号、车辆照片、行驶里程、车辆基础信息、关联司机等信息。",
        },
        {
          title: "车辆实时定位",
          subTitle:
            "在地图界面显示所有校车的实时位置，点击可以进行查看详情、查看历史轨迹、下发工作任务等操作。",
        },
        {
          title: "车辆运行状态监控",
          subTitle:
            "管理员可通过列表或地图上的车辆图查看实时状态，包含车辆载客、行驶速度、行驶里程、车内温度、胎压等实时运行状况。",
        },
        {
          title: "运行线路监控",
          subTitle:
            "实时监控校车行进线路的情况，如遇道路施工、事故、灾害天气等情况则实时展示在地图界面。",
        },
      ],
      taskList: [
        {
          title: "视频云存储",
          subTitle:
            "系统自动将校车采集的视频数据存储至云端，供管理员随时追溯问题。",
        },
        {
          title: "远程视频直播",
          subTitle:
            "驾驶舱管理员可以点击地图上的车辆远程查看车内视频监控、行车记录仪的视频画面，视频画面自动存储至云端。",
        },
        {
          title: "视屏回放",
          subTitle:
            "驾驶舱管理员可以回放查看指定车辆内外视频画面，支持截取视频画面单独存证。",
        },
        {
          title: "事件视频追踪",
          subTitle:
            "驾驶舱管理员可以创建驾驶行为事件，指派工作人员进行问题追溯、现场勘查等操作。",
        },
      ],
      systemList: [
        {
          title: "实时音视频通话",
          subTitle:
            "司机端通过车载终端或手机App发起或接受和驾驶舱的实时音视频通话，管理员可以进行保存视频画面、指派任务等操作。",
        },
      ],
      drivingList: [
        {
          title: "驾驶行为分析",
          subTitle:
            "系统自动记录车辆行驶数据并进行智能分析。系统按照管理员设定时间范围显示车辆行进轨迹，并支持通过轨迹行进回放功能模拟车辆行驶情况。系统会对行进路线进行智能分析，标注超速、急变速等危险驾驶位置。",
        },
        {
          title: "",
          subTitle:
            "管理员调阅现场车内视频，结合视频画面进一步分析事发当时的具体情况；也可以将高风险位置创建任务，指派工作人员现场勘查。",
        },
      ],
      parentsList: [
        {
          title: "校车动态",
          subTitle:
            "学生家长可以通过此界面查看乘车信息，根据车辆到站时间合理安排时间。点击校车即可查看车辆的详情信息，包含车牌号、司机姓名、司机电话。",
        },
        {
          title: "",
          subTitle:
            "车辆的详细信息，包含车牌号、司机姓名、司机电话、车辆途径站点、车辆当前位置等信息。",
        },
      ],
      noticeList: [
        {
          title: "乘车通知",
          subTitle: "学生上学、放学的乘车信息自动推送至学生家长。",
        },
      ],
      myList: [
        {
          title: "学生信息",
          subTitle:
            "学生家长通过认证后即可编辑学生信息，包括姓名、性别、班级、照片等信息。",
        },
        {
          title: "家庭地址",
          subTitle: "家长录入家庭地址信息，并在地图上选择具体的坐标。",
        },
        {
          title: "乘车站点",
          subTitle:
            "家长就近选择离家最近的接送站点，并支持调用导航功能实现从家到乘车站点的导航功能。",
        },
        {
          title: "家庭成员",
          subTitle:
            "家长可以添加管理家庭成员，包含和学生的关系、姓名、联系电话、照片。",
        },
      ],
      driverList: [
        {
          title: "学生接送",
          subTitle:
            "车载终端显示校车行驶路线、途径站点以及各站点接送学生名单，学生刷脸打卡设备确认后方可上车就坐。",
        },
      ],
      messageList: [
        {
          title: "即时通信",
          subTitle:
            "司机发现异常情况时，可以通过车载终端发起音视频通话请求，可以向驾驶舱管理员报告情况。",
        },
      ],
      manageList: [
        {
          title: "校车管理",
          subTitle:
            "对校车进行档案化管理，将校车注册、变更登记、核发校车标牌及校车驾驶人资格审查信息统一录入系统，确保校车信息能够与校车运营单位、所服务学校、司职人员等信息进行关联。",
        },
        {
          title: "",
          subTitle:
            "校车标牌信息包括： 校车的号牌号码、校车类型、品牌型号、核载人数、校车运营单位、驾驶人、发证单位、有效期等事项。",
        },
      ],
      maintenanceList: [
        {
          title: "站点维护",
          subTitle:
            "校车站点维护功能是对校车所有途经站点进行信息维护， 站点与线路进行关联。系统运用GIS空间计算能力根据学生分布情况智能推荐站点选址区域，明确每个学生上下车站点",
        },
      ],
      routeManagementList: [
        {
          title: "路线管理",
          subTitle:
            "对校车进行档案化管理，将校车注册、变更登记、核发校车标牌及校车驾驶人资格审查信息统一录入系统，确保校车信息能够与校车运营单位、所服务学校、司职人员等信息进行关联。",
        },
        {
          title: "",
          subTitle:
            "校车标牌信息包括： 校车的号牌号码、校车类型、品牌型号、核载人数、校车运营单位、驾驶人、发证单位、有效期等事项。",
        },
      ],
      studentManagementList: [
        {
          title: "学生管理",
          subTitle:
            "建立学生档案，包括学生姓名、照片、家庭住址、监护人姓名、监护人电话等信息。通过认证的学生家长可以在家长端上传学生照片、编辑家庭住址信息。",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
// .type-area {
//   width: 1180px;
//   margin: 0 auto;
// }
.dot-plot {
  width: 380px;
  height: 137px;
  text-align: right;
  background: url("~@/assets/images/dot-plot.png") no-repeat;
  background-size: 380px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}
.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    // padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}
.top-banner {
  height: 580px;
  width: 100%;
  background-image: url("~@/assets/images/ban7.png");
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  .banner-text {
    width: 1180px;
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 528px;
    height: 180px;
    color: #b6bfd3;
  }
}
.advantage {
  height: 799px;
  background: #fbfbfb;
  padding-top: 84px;
  &/deep/.el-divider__text {
    background: #fbfbfb;
  }
  .advantage-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -21px;
    .item {
      flex: 0 1 25.1%;
      height: 225px;
      display: flex;
      flex-direction: column;
      margin-top: 107px;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: #1e1e1e;
        line-height: 20px;
        margin: 12px 0 17px 0;
      }
      div {
        width: 260px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(30, 30, 30, 0.67);
        line-height: 28px;
      }
    }
  }
}
.base-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    display: flex;
    width: 654px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    font-size: 36px;
    line-height: 55px;
    font-weight: 300;
    text-align: justify;
    .cut-off {
      margin-bottom: 22px;
      background: #108aee;
      height: 4px;
      width: 52px;
    }
  }
}
.process {
  height: 1007px;
  padding-top: 88px;
  .process-main {
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    img {
      width: 888px;
      height: 534px;
      object-fit: contain;
    }
    & > div {
      //   width: 460px;
      img {
        padding-right: 10px;
        vertical-align: middle;
        width: 40px;
        height: 40px;
      }
      div {
        display: flex;
        align-items: center;
        // flex-direction: column;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 0px;
        }
        span {
          color: #1e1e1e;
          line-height: 1px;
          font-weight: 500;
          font-size: 20px;
          //   margin-bottom: 22px;
        }
      }
    }
  }
}
.engine {
  padding-top: 93px;
  height: 803px;
  background: #f5f7f9;
  .engine-main {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    img {
      object-fit: contain;
      width: 625.7px;
      height: 431px;
    }
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 0;
        }
        span {
          color: #1e1e1e;
          font-weight: 500;
          line-height: 20px;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
    }
  }
}
.task {
  height: 803px;
  .task-main {
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 606px;
      height: 390px;
    }
  }
}
.system {
  height: 803px;
  .system-main {
    padding-top: 74px;
    & > div {
      div {
        &:first-child {
          margin-top: 158px;
        }
        // margin-top: 58px;
      }
    }
    img {
      width: 618px;
      height: 432px;
      margin-left: -45px;
    }
  }
}
.my {
  height: 803px;
  .my-main {
    padding-top: 60px;
    & > div {
      div {
        &:first-child {
          margin-top: 0px;
        }
        // margin-top: 58px;
      }
    }
    img {
      width: 447px;
      height: 465px;
      margin-left: -45px;
    }
  }
}
.driving {
  height: 827px;
  .driving-main {
    padding-top: 90px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 107px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 624px;
      height: 420px;
    }
  }
}
.notice {
  height: 767px;
  .notice-main {
    padding-top: 20px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 152px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 519px;
      height: 464px;
    }
  }
}
.routeManagement {
  height: 767px;
  .routeManagement-main {
    padding-top: 42px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 114px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 574.5px;
      height: 405px;
    }
  }
}
.message {
  height: 803px;
  .message-main {
    padding-top: 76px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 110px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 479px;
      height: 464px;
    }
  }
}
.maintenance {
  height: 803px;
  .maintenance-main {
    padding-top: 69px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 137px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 574.5px;
      height: 405px;
    }
  }
}
.studentManagement {
  height: 803px;
  .studentManagement-main {
    padding-top: 38px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 148px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 447px;
      height: 465px;
    }
  }
}
.guardian {
  height: 926px;
  .divider {
    padding: 0px 250px 90px;
    & /deep/.el-divider__text {
      background-color: #f5f7f9;
    }
  }
  .guardian-main {
    margin-right: 45px;
    padding-top: 0px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 150px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 424.19px;
      height: 517px;
    }
  }
}
.driver {
  height: 893px;
  .divider {
    padding: 0px 250px 90px;
    & /deep/.el-divider__text {
      background-color: #fff;
    }
  }
  .driver-main {
    padding-top: 20px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 152px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 519px;
      height: 464px;
    }
  }
  .manage-main {
    padding-top: 55px;
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        &:first-child {
          margin-top: 88px;
        }
        span {
          margin-bottom: 22px;
        }
      }
    }
    img {
      width: 520px;
      height: 397px;
    }
  }
}
.contact_us {
  height: 289px;
  background: #fff;
  .type-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
  }
  span {
    font-size: 28px;
    line-height: 28px;
    color: #393c40;
    font-weight: 500;
    margin-bottom: 33px;
  }
  &/deep/.el-button {
    width: 161px;
    height: 56px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 0;
    background: #0f80dc;
    border-color: #0f80dc;
    &:hover {
      background: #108aee;
      border-color: #108aee;
    }
  }
}
</style>
